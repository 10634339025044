import Vue from "vue";
import router from "@/router";
import store from "@/state/store";
import i18n from "@/i18n";
import App from "@/App";
import Keycloak from "keycloak-js";
import {userService} from "@/helpers/fakebackend/user.service";
import {globalVariable} from "@/helpers/global-variables";
import {customerService} from "@/helpers/fakebackend/customer.service";

export const initKeyCloackVue = {
    async init(){
        const initOptions = {
            url: globalVariable.keycloak.URL,
            realm: "Kyberio",
            clientId: "kyberio-app-vue",
            onLoad: 'login-required',
        }
        let keycloak = new Keycloak(initOptions);
        const user = localStorage.getItem("user");
        if (location.pathname === '/register' || location.pathname === "/account-verify" || user) {
            this.createAndMountVueApp(keycloak);
        } else {
            keycloak.init({ onLoad: initOptions.onLoad }).then((auth) => {
                if (!auth) {
                    window.location.reload();
                } else {
                    Vue.prototype.$keycloak = keycloak;
                    keycloak.loadUserProfile().then(async (userProfil) => {
                        const user = {
                            uuid: userProfil.id,
                            auth_token: keycloak.token,
                        };
                        localStorage.setItem("user", JSON.stringify(user));
                        await this.initUser(user.uuid);
                        this.createAndMountVueApp(keycloak);
                    });
                }
            }).catch((e) => {
                console.log("Keycloak init error", e);
                localStorage.removeItem("user");
                window.location.reload();
            });
        }
    },

    createAndMountVueApp(keycloak) {
        new Vue({
            router,
            store,
            i18n,
            render: h => h(App, { props: { keycloak: keycloak } })
        }).$mount('#app')
    },

    async initUser(uuid) {
        const userData = await userService.getUser(uuid);
        if (userData.status === globalVariable.api.SUCCESS_STATUS) {
            localStorage.setItem("user_infos", JSON.stringify(userData.content));

            const customerData = await customerService.getCustomer(userData.content.customerUuid);
            if (customerData.status === globalVariable.api.SUCCESS_STATUS)
                localStorage.setItem("customer_infos", JSON.stringify(customerData.content));

            if (userData.content.status !== 1) {
                const registrationData = await customerService.getRegistrationInfos();
                if (customerData.status === globalVariable.api.SUCCESS_STATUS)
                    localStorage.setItem("registration_infos", JSON.stringify(registrationData.content));
            }
        }
    },
}