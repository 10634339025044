import {authJsonHeader} from "@/helpers/fakebackend/auth-header";
import {globalVariable} from "@/helpers/global-variables";
import {userService} from "@/helpers/fakebackend/user.service";

export const customerService = {
    getCustomer,
    sendAuthenticationcode,
    resendAuthenticationcode,
    verifyAuthenticationcode,
    setPassword,
    addCompanyData,
    editCompanyData,
    addPrivateData,
    editPrivateData,
    addSepaPayment,
    editSepaPayment,
    addCreditCardPayment,
    editCreditCardPayment,
    switchToCreditCardPayment,
    switchToSepaPayment,
    addAddress,
    updateAddress,
    getAddress,
    deleteAddress,
    getAddresses,
    setStatus,
    getSepaPayment,
    getCreditcardPayment,
    getRegistrationInfos,
};

function sendAuthenticationcode(email) {
    const requestOptions = {
        method: 'POST',
        headers: authJsonHeader(),
        body: JSON.stringify({email: email})
    };
    return fetch(process.env.VUE_APP_KYB_API_URL +`customer/register`, requestOptions).then(handleResponse);
}

function resendAuthenticationcode(email) {
    const requestOptions = {
        method: 'POST',
        headers: authJsonHeader(),
        body: JSON.stringify({email: email})
    };
    return fetch(process.env.VUE_APP_KYB_API_URL +`customer/register/resend`, requestOptions).then(handleResponse);
}

function verifyAuthenticationcode(code, email) {
    const requestOptions = {
        method: 'POST',
        headers: authJsonHeader(),
        body: JSON.stringify({code: code, email: email})
    };
    return fetch(process.env.VUE_APP_KYB_API_URL +`customer/register/activate`, requestOptions).then(handleResponse);
}

function setPassword(password, repetition, token) {
    localStorage.setItem('user', JSON.stringify({auth_token: token}));
    const requestOptions = {
        method: 'PATCH',
        headers: authJsonHeader(),
        body: JSON.stringify({password: password, repetition: repetition})
    };
    return fetch(process.env.VUE_APP_KYB_API_URL +`customer/register/password`, requestOptions).then(handleResponse);
}

function addCompanyData(data) {
    const requestOptions = {
        method: 'POST',
        headers: authJsonHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_KYB_API_URL +`customer/company`, requestOptions).then(handleResponse);
}

function editCompanyData(data) {
    const requestOptions = {
        method: 'PATCH',
        headers: authJsonHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_KYB_API_URL +`customer/company`, requestOptions).then(handleResponse);
}

function addPrivateData(data) {
    const requestOptions = {
        method: 'POST',
        headers: authJsonHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_KYB_API_URL +`customer/private`, requestOptions).then(handleResponse);
}

function editPrivateData(data) {
    const requestOptions = {
        method: 'PATCH',
        headers: authJsonHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_KYB_API_URL +`customer/private`, requestOptions).then(handleResponse);
}

function addSepaPayment(owner, iban) {
    const requestOptions = {
        method: 'POST',
        headers: authJsonHeader(),
        body: JSON.stringify({owner: owner, iban: iban})
    };
    return fetch(process.env.VUE_APP_KYB_API_URL +`customer/payment/sepa`, requestOptions).then(handleResponse);
}

function editSepaPayment(owner, iban) {
    const requestOptions = {
        method: 'PATCH',
        headers: authJsonHeader(),
        body: JSON.stringify({owner: owner, iban: iban})
    };
    return fetch(process.env.VUE_APP_KYB_API_URL +`customer/payment/sepa`, requestOptions).then(handleResponse);
}

function addCreditCardPayment(cardToken) {
    const requestOptions = {
        method: 'POST',
        headers: authJsonHeader(),
        body: JSON.stringify({card_token: cardToken})
    };
    return fetch(process.env.VUE_APP_KYB_API_URL +`customer/payment/creditcard`, requestOptions).then(handleResponse);
}

function switchToCreditCardPayment(cardToken) {
    const requestOptions = {
        method: 'PUT',
        headers: authJsonHeader(),
        body: JSON.stringify({card_token: cardToken})
    };
    return fetch(process.env.VUE_APP_KYB_API_URL +`customer/payment/creditcard`, requestOptions).then(handleResponse);
}
function switchToSepaPayment(owner,iban) {
    const requestOptions = {
        method: 'PUT',
        headers: authJsonHeader(),
        body: JSON.stringify({owner: owner, iban: iban})
    };
    return fetch(process.env.VUE_APP_KYB_API_URL +`customer/payment/sepa`, requestOptions).then(handleResponse);
}

function editCreditCardPayment(cardToken) {
    const requestOptions = {
        method: 'PATCH',
        headers: authJsonHeader(),
        body: JSON.stringify({card_token: cardToken})
    };
    return fetch(process.env.VUE_APP_KYB_API_URL +`customer/payment/creditcard`, requestOptions).then(handleResponse);
}

function addAddress(address, purpose=""){
    const requestOptions = {
        method: "POST",
        headers: authJsonHeader(),
        body: JSON.stringify(address)
    };
    return fetch(process.env.VUE_APP_KYB_API_URL + `customer/address/${purpose}`, requestOptions).then(handleResponse);
}

function updateAddress(address, purpose=""){
    const requestOptions = {
        method: "PATCH",
        headers: authJsonHeader(),
        body: JSON.stringify(address)
    };
    return fetch(process.env.VUE_APP_KYB_API_URL + `customer/address/${purpose}`, requestOptions).then(handleResponse);
}

function getAddress(purpose=""){
    const requestOptions = {
        method: "GET",
        headers: authJsonHeader(),
    };
    return fetch(process.env.VUE_APP_KYB_API_URL + `customer/address/${purpose}`, requestOptions).then(handleResponse);
}

function deleteAddress(purpose=""){
    const requestOptions = {
        method: "DELETE",
        headers: authJsonHeader(),
    };
    return fetch(process.env.VUE_APP_KYB_API_URL + `customer/address/${purpose}`, requestOptions).then(handleResponse);
}

function getAddresses(){
    const requestOptions = {
        method: "GET",
        headers: authJsonHeader(),
    };
    return fetch(process.env.VUE_APP_KYB_API_URL + `customer/address`, requestOptions).then(handleResponse);
}

function getCustomer(uuid){
    const requestOptions = {
        method: "GET",
        headers: authJsonHeader(),
    };
    return fetch(process.env.VUE_APP_KYB_API_URL + `customer/${uuid}`, requestOptions).then(handleResponse);
}

function getRegistrationInfos(){
    const requestOptions = {
        method: "GET",
        headers: authJsonHeader(),
    };
    return fetch(process.env.VUE_APP_KYB_API_URL + `customer/registration`, requestOptions).then(handleResponse);
}

function setStatus(status="view"){
    const requestOptions = {
        method: "PATCH",
        headers: authJsonHeader(),
        body: JSON.stringify({mode: status})
    };
    return fetch(process.env.VUE_APP_KYB_API_URL + `customer/register/status`, requestOptions).then(handleResponse);
}

function getCreditcardPayment(){
    const requestOptions = {
        method: "GET",
        headers: authJsonHeader(),
    };
    return fetch(process.env.VUE_APP_KYB_API_URL + `customer/payment/creditcard`, requestOptions).then(handleResponse);
}

function getSepaPayment(){
    const requestOptions = {
        method: "GET",
        headers: authJsonHeader(),
    };
    return fetch(process.env.VUE_APP_KYB_API_URL + `customer/payment/sepa`, requestOptions).then(handleResponse);
}

async function handleResponse(response) {
    if (response.ok) {
        return response.json();
    }else{
        if (response.status === 401) {
            userService.logout();
        }
        if (response.status >= 500) {
            throw {code: globalVariable.api.ERROR_500};
        }
        throw await response.json();
    }
}
