<template>
  <div>
    <h2 class="text-center mb-5">Ihre nächsten Schritte</h2>
    <div class="row justify-content-center">
      <div class="col-md-5 text-center mb-5">
        <div class="mb-4"><img src="@/assets/images/icons/complete-infos.png"  height="80" alt=""></div>
        <h5 class="mb-5">Daten vervollständigen, <br> Zahlungsart hinterlegen und <br> volle Power genießen</h5>
        <button class="btn btn-primary" v-on:click="next">Daten vervollständigen</button>
      </div>
      <div class="col-md-5 text-center  mb-5 border-left">
        <div class="mb-4"><img src="@/assets/images/icons/eye-loop.png"  height="80" alt=""></div>
        <h5 class="mb-5">Kostenlos umschauen* <br> ohne weitere Dateneingabe <br><br></h5>
        <div class="mb-4"><button v-on:click="close" class="btn btn-primary">Erstmal umschauen <i class="ri-loader-line" v-if="isLoading"></i></button></div>
        <p class="pl-5 pr-5"><small>*Nach Vervollständigung der Daten, kann der Account direkt ohne Datenverluste genutzt werden</small></p>
      </div>
    </div>
  </div>
</template>

<script>
import {customerService} from "@/helpers/fakebackend/customer.service";

export default {
  name: "first-step",
  data(){
    return {
      isLoading: false,
    }
  },
  methods: {
    close(){
      this.isLoading = true;
      customerService.setStatus().then(()=>{
        this.isLoading = false;
        const customer = JSON.parse(localStorage.getItem("customer_infos"));
        if (customer){
          customer.status = 2;
          localStorage.setItem("customer_infos", JSON.stringify(customer))
        }
        this.$emit("close", customer);
      }).catch(()=>{
        this.isLoading = false;
      })

    },
    next(){
      this.$emit("next");
    }
  }
}
</script>

<style scoped>
.border-left{
  border-left-width: 2px!important;
  border-left-color: #00e200!important;
}
</style>