<template>
  <div id="app">
    <info-bar></info-bar>
    <router-view />
  </div>
</template>

<script>
import appConfig from "@/app.config";
import InfoBar from "@/components/info-bar";

export default {
  name: "app",
  components: {InfoBar},
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${title} | ${appConfig.title}` : appConfig.title;
    }
  },
  updated() {
    this.passwordFields();
  },
  methods:{
    passwordFields(){
      const passwordFields = document.querySelectorAll(".password-container input[type='password']");
      passwordFields.forEach((passwordField)=>{
        let hideBtn = passwordField.nextElementSibling;

        hideBtn = this.removeAllListeners(hideBtn);

        hideBtn.addEventListener("click", function(){
          if (passwordField.type === "password"){
            passwordField.type = "text";
            hideBtn.firstElementChild.classList.remove("fa-eye");
            hideBtn.firstElementChild.classList.add("fa-eye-slash");
          }else{
            passwordField.type = "password";
            hideBtn.firstElementChild.classList.remove("fa-eye-slash");
            hideBtn.firstElementChild.classList.add("fa-eye");
          }
        });
      });
    },
    removeAllListeners(element){
      const elClone = element.cloneNode(true);
      element.parentNode.replaceChild(elClone, element);
      return elClone;
    }
  },
};
</script>