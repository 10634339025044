<template>
  <div>
    <h2 class="mb-5"><span class="text-primary"><b>Schritt 1 von 2:</b></span> Ihre Daten</h2>
    <b-alert show :variant="globalMessage.type" v-if="globalMessage.message">{{ globalMessage.message }}</b-alert>
    <b-overlay :show="loading" rounded="sm">
      <b-form v-on:submit="submit">
        <div class="row">
          <div class="col-md-12 mb-5">
            <div class="d-flex">
              <div class="custom-control custom-checkbox">
                <input
                    type="radio"
                    v-model="datatype"
                    value="enterprise"
                    name="datatype"
                    class="custom-control-input"
                    id="enterprise"
                />
                <label
                    class="custom-control-label"
                    for="enterprise"
                >Unternehmen</label>
              </div>
              <div class="custom-control custom-checkbox ml-3">
                <input
                    type="radio"
                    v-model="datatype"
                    value="private"
                    name="datatype"
                    class="custom-control-input"
                    id="private"
                />
                <label
                    class="custom-control-label"
                    for="private"
                >Privatperson</label>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group auth-form-group-custom mb-4">
              <label for="name"><span v-if="datatype==='enterprise'">Firmenname*</span><span v-if="datatype!=='enterprise'">Name*</span></label>
              <b-form-input
                  id="name"
                  placeholder="Firmenname*"
                  v-model="address.organization"
                  required
                  type="text"
              ></b-form-input>
            </div>

            <div class="form-group auth-form-group-custom mb-4">
              <label for="street" class="mt-4">Firmenanschrift</label>
              <b-form-input
                  v-model="address.street"
                  id="street"
                  placeholder="Straße"
                  type="text"
                  required
              ></b-form-input>
            </div>
            <div class="row justify-content-between mb-4">
              <div class="col-5">
                <b-form-input
                    v-model="address.zipCode"
                    id="zipCode"
                    placeholder="PLZ"
                    type="text"
                    required
                ></b-form-input>
              </div>
              <div class="col-7">
                <b-form-input
                    v-model="address.city"
                    id="city"
                    placeholder="Stadt"
                    type="text"
                    required
                ></b-form-input>
              </div>
            </div>
            <div class="form-group auth-form-group-custom ">
              <b-form-select v-model="address.country" v-on:change="changeCountry" required :options="selectedCountries"></b-form-select>
            </div>

          </div>

          <div class="col-md-6">
            <div class="form-group auth-form-group-custom mb-4" v-if="datatype==='enterprise'">
              <label for="contact">Ansprechperson*</label>
              <b-form-input
                  id="contact"
                  placeholder="Ansprechperson"
                  v-model="masterData.name"
                  type="text"
                  required
              ></b-form-input>
            </div>
            <div class="form-group auth-form-group-custom mb-4">
              <label for="email" :class="datatype==='enterprise'?'mt-4':''">E-Mail-Adresse*</label>
              <b-form-input
                  v-model="masterData.email"
                  id="email"
                  placeholder="E-Mail-Adresse*"
                  type="email"
                  required
              ></b-form-input>
            </div>
            <div class="form-group auth-form-group-custom mb-4" v-if="datatype==='enterprise'">
              <label for="taxNumber">USt-ID*</label>
              <b-form-input
                  v-model="masterData.taxNumber"
                  id="taxNumber"
                  placeholder="(Pflichtfeld EU Ausland)"
                  type="text"
                  :required="ustdidRequired"
              ></b-form-input>
            </div>
          </div>

          <div class="col-md-12">
            <div class="text-left">
              <button class="btn btn-primary"><span v-if="!loading">Nächster Schritt</span><span v-if="loading">Warten...</span></button>
            </div>
          </div>
        </div>
      </b-form>
    </b-overlay>
  </div>
</template>

<script>
import {systemService} from "@/helpers/fakebackend/system.service";
import {customerService} from "@/helpers/fakebackend/customer.service";
import {requestMessage} from "@/helpers/request-message";
const DATA_TYPE_ENTERPRISE = "enterprise";
const DATA_TYPE_PRIVATE = "private";
export default {
  name: "profil-data",
  data(){
    return{
      loading: true,
      globalMessage: {message: "", type: "danger"},
      datatype: DATA_TYPE_ENTERPRISE,
      address: {},
      masterData: {},
      privateCountries: [],
      companyCountries: [],
      selectedCountries: [],
      ustdidRequired: false,
    }
  },
  async created() {
    await this.getCountries();
    this.loading = false;
  },
  methods: {
    close(){
      this.$emit("close");
    },
    next(){
      this.$emit("next");
    },
    changeCountry(country){
      this.ustdidRequired = country.requiresTaxNumber === 1;
    },
    submit(e){
      e.preventDefault();
      this.loading = true;
      const address = Object.assign({}, this.address);
      address.country = this.address.country.code;
      address.purpose = "main";
      if (this.datatype === DATA_TYPE_ENTERPRISE){
        address.name = address.organization;
        customerService.addAddress(address, "main").then(()=>{
          customerService.addCompanyData(this.masterData).then((data)=>{
            this.nextStep(data.content);
          }).catch(this.handleError);
        }).catch((data)=>{
          // the principal address already exists
          if (data.code === "cust-0014-0004"){
            customerService.addCompanyData(this.masterData).then((data)=>{
              this.nextStep(data.content);
            }).catch(this.handleError);
          }else
            this.handleError(data)
        });
      }else{
        this.masterData.name = address.name = this.address.organization;
        customerService.addAddress(address, "main").then(()=>{
          customerService.addPrivateData(this.masterData).then((data)=>{
            this.nextStep(data.content);
          }).catch(this.handleError);
        }).catch((data)=>{
          // the principal address already exists
          if (data.code === "cust-0014-0004"){
            customerService.addPrivateData(this.masterData).then((data)=>{
              this.nextStep(data.content);
            }).catch(this.handleError);
          }else
            this.handleError(data)
        });
      }
    },
    handleError(data){
      this.loading = false;
      this.globalMessage.message = requestMessage[data.code].de??"Ein Fehler ist aufgetreten";
      this.globalMessage.type = "danger";
    },
    async getCountries(){
      await systemService.getCompanyCountries().then((data)=>{
        this.companyCountries = data.content;
        if (this.datatype === DATA_TYPE_ENTERPRISE)
          this.selectCountries(this.companyCountries);
      });
      await systemService.getPrivateCountries().then((data)=>{
        this.privateCountries = data.content;
        if (this.datatype === DATA_TYPE_PRIVATE)
          this.selectCountries(this.privateCountries);
      })
    },
    nextStep(customer){
      this.loading = false;
      this.updateLocalStatus(customer);
      this.next();
    },
    selectCountries(countries){
      let _countries = [];
      this.address.country = countries[0];
      this.ustdidRequired = countries[0].requiresTaxNumber === 1;
      countries.forEach(country=>{
        _countries.push({text: country.name, value: country})
      });
      this.selectedCountries = _countries;
    },
    updateLocalStatus(customer){
      const registrationInfos = JSON.parse(localStorage.getItem("registration_infos"));
      registrationInfos.hasAddress = 1;
      registrationInfos.hasData = 1;
      localStorage.setItem("customer_infos", JSON.stringify(customer));
      localStorage.setItem("registration_infos", JSON.stringify(registrationInfos));
      this.$root.$emit("popup_address_added", customer);
    }
  }
}
</script>

<style scoped>
</style>