<template>
  <b-overlay :show="loading" rounded="sm">
    <b-alert show :variant="globalMessage.type" v-if="globalMessage.message">{{ globalMessage.message }}</b-alert>
    <b-form v-on:submit="submit">
      <div class="row">
        <div class="col-md-12 mb-5">
          <div class="d-flex">
            <div class="custom-control custom-checkbox">
              <input
                  type="radio"
                  v-model="paymentType"
                  value="sepa"
                  name="paymentType"
                  class="custom-control-input"
                  id="sepa"
                  required
              />
              <label
                  class="custom-control-label"
                  for="sepa"
              >SEPA-Lastschriftverfahren</label>
            </div>
            <div class="custom-control custom-checkbox ml-3">
              <input
                  type="radio"
                  v-model="paymentType"
                  value="stripe"
                  name="paymentType"
                  class="custom-control-input"
                  id="stripe"
                  required
              />
              <label
                  class="custom-control-label"
                  for="stripe"
              >Kreditkarte (via stripe.com)</label>
            </div>
          </div>
        </div>

        <div class="col-md-7" v-show="paymentType==='sepa'">
          <div class="form-group auth-form-group-custom mb-4">
            <label for="owner">Konto-Inhaber*</label>
            <b-form-input
                id="owner"
                placeholder="Konto-Inhaber*"
                v-model="owner"
                required
                type="text"
            ></b-form-input>
          </div>
          <div class="form-group auth-form-group-custom mb-4">
            <label for="iban">IBAN*</label>
            <b-form-input
                id="iban"
                v-model="iban"
                required
                type="text"
            ></b-form-input>
          </div>
          <div class="custom-control custom-checkbox mt-3 mb-4">
            <input
                type="checkbox"
                v-model="accept"
                required
                name="paymentType"
                class="custom-control-input"
                id="accept"
            />
            <label
                class="custom-control-label font-weight-normal"
                for="accept"
            >Ich stimme zu, dass fällige Beträge bis auf Widerruf vom angegebenen Konto abgebucht werden dürfen.</label>
          </div>
        </div>
        <div class="col-md-7" v-show="paymentType==='stripe'">
          <stripe-payment-method :payment="paymentMethod" v-on:complete="closePaymentModal"></stripe-payment-method>
        </div>
        <div class="col-md-7" v-if="close">
<!--          <button class="btn btn-primary" v-on:click="prev">Zurück</button>-->
          <button class="btn btn-primary float-right" type="submit" v-show="paymentType==='sepa'"><span v-if="!loading">Abschließen</span><span v-if="loading">Warten...</span></button>
        </div>
        <div class="col-md-7" v-if="!close">
          <button class="btn btn-primary pr-3 pl-3" type="submit" v-show="paymentType==='sepa'"><span v-if="!loading">Speichern</span><span v-if="loading">Warten...</span></button>
        </div>
      </div>
    </b-form>
  </b-overlay>
</template>

<script>
import {customerService} from "@/helpers/fakebackend/customer.service";
import Swal from "sweetalert2";
import StripePaymentMethod from "@/components/StripePaymentMethod";
import {requestMessage} from "@/helpers/request-message";

const PAYMENT_TYPE_SEPA = "sepa";
const PAYMENT_TYPE_STRIPE = "stripe";
export default {
  name: "PaymentMethod",
  components: {StripePaymentMethod},
  props: ["close", "paymentMethod"],
  data() {
    return {
      loading: false,
      globalMessage: {message: "", type: "danger"},
      paymentType: PAYMENT_TYPE_SEPA,
      accept: false,
      owner: "",
      iban: "",
      stripe: {},
    }
  },
  mounted(){
    if (this.paymentMethod && this.paymentMethod.owner){
      this.paymentType = this.paymentMethod.type;
      this.stripe = this.paymentMethod;
      if (this.paymentType !== PAYMENT_TYPE_SEPA) {
        this.stripe.number = this.paymentMethod.iban;
        this.stripe.owner = this.paymentMethod.owner;
      }else{
        this.iban = this.paymentMethod.iban;
        this.owner = this.paymentMethod.owner;
      }
    }
  },
  methods: {
    prev(){
      this.$emit("prev");
    },
    submit(e){
      e.preventDefault();
      if(this.paymentMethod && this.paymentMethod.owner){
        if (this.paymentType === this.paymentMethod.type)
          this.updatePayment();
        else
          this.switchPayment();
      }else
        this.addPayment();
    },
    addPayment(){
      this.loading = true;
      if (this.paymentType === PAYMENT_TYPE_SEPA){
        customerService.addSepaPayment(this.owner, this.iban).then((data)=>{
          this.loading = false;
          Swal.fire("Ihre Bankdaten wurden erfolgreich hinzugefügt", "", "success");
          if (data.content)
            data.content.type = PAYMENT_TYPE_SEPA;
          this.updateLocalStatus();
          this.sendDataToParent(data.content);
        }).catch(this.handleError);
      }else{
        customerService.addCreditCardPayment(this.stripe.token).then((data)=>{
          this.loading = false;
          Swal.fire("Ihre Bankdaten wurden erfolgreich hinzugefügt", "", "success");

          if (data.content)
            data.content.type = PAYMENT_TYPE_STRIPE;
          this.updateLocalStatus();
          this.sendDataToParent(data.content);
        }).catch(this.handleError);
      }
    },
    updatePayment(){
      this.loading = true;
      if (this.paymentType === PAYMENT_TYPE_SEPA){
        customerService.editSepaPayment(this.owner, this.iban).then((data)=>{
          this.loading = false;
          Swal.fire("Ihre Bankdaten wurden erfolgreich geändert", "", "success");
          data.content.type = PAYMENT_TYPE_SEPA;
          this.sendDataToParent(data.content);
        }).catch(this.handleError);
      }else{
        this.$bvModal.hide("stripe-modal-payment");
        customerService.editCreditCardPayment(this.stripe.token).then((data)=>{
          this.loading = false;
          Swal.fire("Ihre Bankdaten wurden erfolgreich geändert", "", "success");
          data.content.type = PAYMENT_TYPE_STRIPE;
          this.sendDataToParent(data.content);
        }).catch(this.handleError);
      }
    },
    switchPayment(){
      this.loading = true;
      if (this.paymentType === PAYMENT_TYPE_SEPA){
        customerService.switchToSepaPayment(this.owner, this.iban).then((data)=>{
          this.loading = false;
          data.content.type = PAYMENT_TYPE_SEPA;
          Swal.fire("Ihre Bankdaten wurden erfolgreich geändert", "", "success");
          this.sendDataToParent(data.content);
        }).catch(this.handleError);
      }else{
        this.$bvModal.hide("stripe-modal-payment");
        customerService.switchToCreditCardPayment(this.stripe.token).then((data)=>{
          this.loading = false;
          data.content.type = PAYMENT_TYPE_STRIPE;
          this.sendDataToParent(data.content);
          Swal.fire("Ihre Bankdaten wurden erfolgreich geändert", "", "success");
        }).catch(this.handleError);
      }
    },
    handleError(data){
      this.loading = false;
      this.globalMessage.message = requestMessage[data.code].de??"Ein Fehler ist aufgetreten";
      this.globalMessage.type = "danger";
    },
    sendDataToParent(data){
      this.$emit("submitted", data);
    },
    closePaymentModal(data){
      this.stripe.token = data.token;
      if(this.paymentMethod && this.paymentMethod.owner) {
        if (this.paymentType === this.paymentMethod.type)
          this.updatePayment();
        else
          this.switchPayment();
      }else
        this.addPayment();
    },
    updateLocalStatus(){
      const customer = JSON.parse(localStorage.getItem("customer_infos"));
      const registrationInfos = JSON.parse(localStorage.getItem("registration_infos"));
      customer.status = 1;
      customer.paymentMethod = this.paymentType === PAYMENT_TYPE_SEPA ? 1 : 0;
      localStorage.setItem("customer_infos", JSON.stringify(customer));
      if (registrationInfos){
        registrationInfos.hasPayment = 1;
        localStorage.setItem("registration_infos", JSON.stringify(registrationInfos));
      }
      this.$root.$emit("popup_payment_added", customer);
    }
  }
}
</script>

<style scoped>

</style>