<template>
    <div class="complete-profile" v-if="!closed && step < 4">
      <div class="card">
        <div v-if="step>1"><a href="" v-on:click="(e)=> {e.preventDefault();close()}" class="float-right"><i class="ri-close-line ri-2x"></i></a></div>
        <first-step v-on:close="closeEvent" v-on:next="step += 1" v-show="step===1"></first-step>
          <profil-data v-on:close="close" v-on:next="step += 1" v-if="step===2"></profil-data>
          <payment-method v-on:close="close" v-on:next="()=>{step += 1; close()}" v-on:prev="step -= 1" v-show="step===3"></payment-method>
      </div>
    </div>
</template>

<script>
import FirstStep from "./first-step";
import ProfilData from "./profil-data";
import PaymentMethod from "./payment-method";
export default {
  name: "index",
  components: {
    FirstStep,
    ProfilData,
    PaymentMethod,
  },
  data(){
    return {
      step: 1,
      modalClosed: true,
    }
  },
  props: ["registrationInfos", "closed"],
  created() {
    this.init();
  },
  methods:{
    init(){
      const customer = JSON.parse(localStorage.getItem("customer_infos"));
      if (customer) {
        if (customer.status === 3)
          this.step = 1;
        else
          this.step = 2;
      }
      if (this.registrationInfos.hasData && this.registrationInfos.hasAddress)
        this.step = 3;
    },
    closeEvent(){
      this.init();
      this.close();
    },
    close(){
      this.$emit("close", true);
    }
  },
}
</script>

<style scoped>
.complete-profile{
  background-color: rgb(173 173 173 / 40%);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1039;
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
}
.complete-profile .card{
  width: 60%;
  margin: 3rem auto;
  padding: 2rem;
  height: auto;
}
@media (max-width: 769px){
  .complete-profile .card{
    width: 90%;
  }
}
</style>