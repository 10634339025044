<template>
  <div>
    <h2 class="mb-5"><span class="text-primary"><b>Schritt 2 von 2:</b></span> Ihre Daten</h2>
    <PaymentMethod close="true" v-on:prev="prev" v-on:submitted="close"></PaymentMethod>
  </div>
</template>

<script>
import PaymentMethod from "@/views/pages/customer_base_data/Components/PaymentMethod";
export default {
  name: "profil-data",
  components: {PaymentMethod},
  data(){
    return{
    }
  },
  methods: {
    close(){
      this.$emit("close");
    },
    prev(){
      this.$emit("prev");
    },
    next(){
      this.$emit("next");
    },
  }
}
</script>

<style scoped>
.custom-checkbox label{
  font-weight: 500;
}
</style>