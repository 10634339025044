import { userService } from '../../helpers/fakebackend/user.service';
import router from '../../router/index'
import {globalVariable} from "@/helpers/global-variables";
import {requestMessage} from "@/helpers/request-message";

const user = JSON.parse(localStorage.getItem('user'));
export const state = user
    ? { status: { loggeduser: true }, user }
    : { status: {}, user: null };


export const actions = {
    // Logs in the user.
    // eslint-disable-next-line no-unused-vars
    login({ dispatch, commit }, { email, password }) {
        commit('loginRequest', { email });
        userService.login(email, password)
            .then(
                async (data) => {
                    if(data.status === globalVariable.api.SUCCESS_STATUS) {
                        router.push('/');
                    }else{
                        commit('loginFailure', requestMessage[data.code].de);
                        dispatch('notification/error', requestMessage[data.code].de, { root: true });
                    }

                },
                data => {
                    if (data.code === globalVariable.api.USER_VERIFY_EMAIL)
                        router.push({name: "email-verify"});
                    else{
                        commit('loginFailure', requestMessage[data.code].de);
                        dispatch('notification/error', requestMessage[data.code].de, { root: true });
                    }
                }
            );
    },
    // Logout the user
    logout({ commit }) {
        userService.logout(false);
        commit('logout');
    },
    // register the user
    registeruser({ dispatch, commit }, user) {
        commit('registerRequest', user);
        userService.register(user)
            .then(
                user => {
                    commit('registerSuccess', user);
                    dispatch('notification/success', 'Registration successful', { root: true });
                    router.push('/login');
                },
                error => {
                    commit('registerFailure', error);
                    dispatch('notification/error', error, { root: true });
                }
            );
    },
    // reset password
    resetPassword({ dispatch}, email) {
        userService.resetPassword(email)
            .then(
                user => {
                    dispatch('notification/success', user.name +', ein neues Password wurde Ihnen per Email gesendet.', { root: true });
                },
                error => {
                    dispatch('notification/error', error, { root: true });
                }
            );
    }
};

export const mutations = {
    loginRequest(state, user) {
        state.status = { loggingIn: true };
        state.user = user;
    },
    loginSuccess(state, user) {
        state.status = { loggeduser: true };
        state.user = user;
    },
    loginFailure(state) {
        state.status = {};
        state.user = null;
    },
    logout(state) {
        state.status = {};
        state.user = null;
    },
    registerRequest(state) {
        state.status = { registering: true };
    },
    registerSuccess(state) {
        state.status = {};
    },
    registerFailure(state) {
        state.status = {};
    },
    passwordResetSuccess(state, user) {
        state.status = { loggeduser: true };
        state.user = user;
    },
};

