import {authHeader, authJsonHeader} from './auth-header';
import {globalVariable} from "@/helpers/global-variables";
import {customerService} from "@/helpers/fakebackend/customer.service";

export const userService = {
    login,
    logout,
    logoutBackend,
    register,
    resetPassword,
    verifyAuthenticationcode,
    setPassword,
    addPasswordUserAccount,
    changePassword,
    getAll,
    changeUserStatus,
    deleteUser,
    editUser,
    addUser,
    getUser,
    userVerify,
    userVerifyEmail,
    notloggedUserVerifyEmail,
    userVerifyAccount,
    extendInvitation,
    notloggedverifyEMailResendCode,
    verifyEMailResendCode,
    verifyUserResendCode,
    LOCK_USER: "lock",
    UNLOCK_USER: "unlock",
};

async function login(user) {
    localStorage.setItem("user", JSON.stringify(user));
    const userData = await this.getUser(user.uuid);
    if (userData.status === globalVariable.api.SUCCESS_STATUS) {
        localStorage.setItem("user_infos", JSON.stringify(userData.content));
        const customerData = await customerService.getCustomer(userData.content.customerUuid);
        if (customerData.status === globalVariable.api.SUCCESS_STATUS)
            localStorage.setItem("customer_infos", JSON.stringify(customerData.content));

        if (userData.content.status !== 1) {
            const registrationData = await customerService.getRegistrationInfos();
            if (customerData.status === globalVariable.api.SUCCESS_STATUS)
                localStorage.setItem("registration_infos", JSON.stringify(registrationData.content));
        }
    }
    return userData;
}

async function logout(removeOnlyUserInfos= true) {
    if (!removeOnlyUserInfos) {
        await this.logoutBackend();
    }
    localStorage.removeItem('user');
    localStorage.removeItem('user_infos');
    localStorage.removeItem('customer_infos');
    localStorage.removeItem('registration_infos');
    //window.location.assign("/");
}

function logoutBackend() {
    const requestOptions = {
        method: 'POST',
        headers: authJsonHeader(),
    };
    return fetch(process.env.VUE_APP_KYB_API_URL + `user/logout`, requestOptions).then(handleResponse);
}

function register(email) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({email: email})
    };
    return fetch(process.env.VUE_APP_KYB_API_URL + `users/register`, requestOptions).then(handleResponse);
}

function resetPassword(email) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({"emailaddress": email}),
    };
    return fetch(process.env.VUE_APP_KYB_API_URL + `user/recover`, requestOptions).then(handleResponse);
}

function verifyAuthenticationcode(code, email) {
    const requestOptions = {
        method: 'POST',
        headers: authJsonHeader(),
        body: JSON.stringify({code: code, emailaddress: email})
    };
    return fetch(process.env.VUE_APP_KYB_API_URL + `user/recover/activate`, requestOptions).then(handleResponse);
}

function setPassword(password, repetition, token) {
    localStorage.setItem('user', JSON.stringify({auth_token: token}));
    const requestOptions = {
        method: 'PATCH',
        headers: authJsonHeader(),
        body: JSON.stringify({password: password, repetition: repetition})
    };
    return fetch(process.env.VUE_APP_KYB_API_URL + `user/recover/password`, requestOptions).then(handleResponse);
}

function changePassword(data) {
    const requestOptions = {
        method: 'PATCH',
        headers: authJsonHeader(),
        body: JSON.stringify(data)
    };
    return fetch(process.env.VUE_APP_KYB_API_URL + `user/profile/password`, requestOptions).then(handleResponse);
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_KYB_API_URL + `user`, requestOptions).then(handleResponse);
}

function changeUserStatus(userId, param) {
    const requestOptions = {
        method: 'PATCH',
        headers: authJsonHeader()
    };
    return fetch(process.env.VUE_APP_KYB_API_URL + `user/${userId}/${param}`, requestOptions).then(handleResponse);
}

function extendInvitation(userId) {
    const requestOptions = {
        method: 'PATCH',
        headers: authJsonHeader()
    };
    return fetch(process.env.VUE_APP_KYB_API_URL + `user/${userId}/extendInvitation`, requestOptions).then(handleResponse);
}

/**
 * verify the new email address for the user (is calling when the user change his email or when an admin
 * change the email for a user)
 * @param code
 * @param email
 * @returns {Promise<*>}
 */
function userVerify(code, email) {
    const requestOptions = {
        method: 'PATCH',
        headers: authJsonHeader(),
        body: JSON.stringify({code: code, emailaddress: email})
    };
    return fetch(process.env.VUE_APP_KYB_API_URL + `user/verify`, requestOptions).then(handleResponse);
}


/**
 * verify the changed email address for the user (is calling when the user change his email)
 * @param code
 * @param email
 * @returns {Promise<*>}
 */
function userVerifyEmail(code, email) {
    const requestOptions = {
        method: 'PATCH',
        headers: authJsonHeader(),
        body: JSON.stringify({code: code, email: email})
    };
    return fetch(process.env.VUE_APP_KYB_API_URL + `user/verify/email`, requestOptions).then(handleResponse);
}


/**
 * verify the changed email address for the user(when the user is not logged) (is calling when the user change his email)
 * @param code
 * @param email
 * @returns {Promise<*>}
 */
function notloggedUserVerifyEmail(code, email) {
    const requestOptions = {
        method: 'PATCH',
        headers: authJsonHeader(),
        body: JSON.stringify({code: code, email: email})
    };
    return fetch(process.env.VUE_APP_KYB_API_URL + `user/verify/account/email`, requestOptions).then(handleResponse);
}

/**
 * verify the  email address for the user added by an admin
 * @param code
 * @param email
 * @returns {Promise<*>}
 */
function userVerifyAccount(code, email) {
    const requestOptions = {
        method: 'PATCH',
        headers: authJsonHeader(),
        body: JSON.stringify({code: code, email: email})
    };
    return fetch(process.env.VUE_APP_KYB_API_URL + `user/verify/account`, requestOptions).then(handleResponse);
}

/**
 * to add the password for the new created user by the admin
 * @param password
 * @param repetition
 * @param token
 * @returns {Promise<*>}
 */
function addPasswordUserAccount(password, repetition, token) {
    localStorage.setItem('user', JSON.stringify({auth_token: token}));
    const requestOptions = {
        method: 'POST',
        headers: authJsonHeader(),
        body: JSON.stringify({password: password, repetition: repetition})
    };
    return fetch(process.env.VUE_APP_KYB_API_URL + `user/verify/account/password`, requestOptions).then(handleResponse);
}

/**
 * Resend a new code for the validation of the new email when the user is logged
 * @param email
 * @returns {Promise<*>}
 */
function verifyEMailResendCode(email) {
    const requestOptions = {
        method: 'POST',
        headers: authJsonHeader(),
        body: JSON.stringify({email: email})
    };
    return fetch(process.env.VUE_APP_KYB_API_URL + `user/verify/email/resend`, requestOptions).then(handleResponse);
}

/**
 * Resend a new code for the validation of the new email when the user isn't logged
 * @param email
 * @returns {Promise<*>}
 */
function notloggedverifyEMailResendCode(email) {
    const requestOptions = {
        method: 'POST',
        headers: authJsonHeader(),
        body: JSON.stringify({email: email})
    };
    return fetch(process.env.VUE_APP_KYB_API_URL + `user/verify/account/email/resend`, requestOptions).then(handleResponse);
}

/**
 * Resend a new code for the validation of the account the user created by the admin
 * @param email
 * @returns {Promise<*>}
 */
function verifyUserResendCode(email) {
    const requestOptions = {
        method: 'POST',
        headers: authJsonHeader(),
        body: JSON.stringify({email: email})
    };
    return fetch(process.env.VUE_APP_KYB_API_URL + `user/verify/account/email/resend`, requestOptions).then(handleResponse);
}

function deleteUser(userId) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
    return fetch(process.env.VUE_APP_KYB_API_URL + `user/${userId}`, requestOptions).then(handleResponse);
}

function editUser(user) {
    const requestOptions = {
        method: 'PATCH',
        headers: authJsonHeader(),
        body: JSON.stringify(user),
    };
    return fetch(process.env.VUE_APP_KYB_API_URL + `user/${user.uuid}`, requestOptions).then(handleResponse);
}

function addUser(user) {
    const requestOptions = {
        method: 'POST',
        headers: authJsonHeader(),
        body: JSON.stringify(user),
    };
    return fetch(process.env.VUE_APP_KYB_API_URL + `user`, requestOptions).then(handleResponse);
}

function getUser(userId) {
    const requestOptions = {
        method: 'GET',
        headers: authJsonHeader(),
    };
    return fetch(process.env.VUE_APP_KYB_API_URL + `user/${userId}`, requestOptions).then(handleResponse);
}

function updateUserSocial() {
    const requestOptions = {
        method: 'POST',
        headers: authJsonHeader(),
    };
    return fetch(process.env.VUE_APP_KYB_API_URL + `customer/register/social`, requestOptions);
}

async function handleResponse(response) {
    if (response.ok) {
        return response.json();
    } else {
        const responseData = await response.json();
        if(response.status === 404) {
            if (responseData.action === 'getUser' && responseData.code === 'user-0005-0005') {
                // social registration - IDs need to be set on backend - send special request
                let userData = null;
                await updateUserSocial().then(async (response) => {
                    console.log('response from the updateUserSocial ', response);
                    if(response.ok) {
                        const user= JSON.parse(localStorage.getItem('user'));
                        userData = await getUser(user.uuid);
                        console.log('userData from the handleResponse in the if reponse is ok ', userData)
                        return userData;
                    } else
                        console.log('response is not ok ', response);
                });
                console.log('userData from the handleResponse ', userData)
                return userData;
            }
        }
        if (response.status === 401) {
            logout();
        }
        if (response.status >= 500) {
            throw {code: globalVariable.api.ERROR_500};
        }
        throw responseData;
    }
}
