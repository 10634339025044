<script>
import { StripeElementCard } from '@vue-stripe/vue-stripe';
export default {
  name: "StripePaymentMethod",
  props: ["payment"],
  components: {
    StripeElementCard,
  },
  data () {
    return {
      publishableKey: "pk_test_51LUrlFEeyuGmMFvorNH1GNUCB1ySZSgbeFFMnZdHN4XSDIlUeJ5Uq8CtdryBbGbS56SSfDkYWpkCT0BeY8bdOauC0085ETWEM4",
      token: null,
      tokenData: {
        name: "",
        address_country: "DE",
        address_city: "",
      },
    };
  },
  mounted(){
    if (this.payment && this.payment.owner){
      //this.tokenData.number = this.payment.iban;
      this.tokenData.name = this.payment.owner;
    }
  },
  methods: {

    submit(e) {
      e.preventDefault();
      this.$refs.elementRef.submit();
    },
    tokenCreated (data) {
      this.token = data.id;
      if (data && data.id)
        this.$emit("complete", {token: data.id})
    },
    changeCountry(country){
      this.tokenData.address_country = country;
    },
  }
}
</script>

<template>
  <div>
    <div class="mb-2">
      <div class="mb-2">Wir akzeptieren Visa, Mastercard, American, Express, UnionPay und Discover-Kreditkarten. </div>
      <div>Es kann sein, dass Ihre Karte vorübergehend gesperrt ist, was Ihre Bank aber bald aufheben sollte. DigitalOcean belastet Sie erst, wenn Sie kostenpflichtige Dienste in Anspruch nehmen</div>
    </div>

    <form  @submit="submit">
      <div class="form-group">
        <stripe-element-card
            ref="elementRef"
            :pk="publishableKey"
            @token="tokenCreated"
            :tokenData="tokenData"
            style="border: 1px solid #e0e0e0"
        />
      </div>
      <div class="form-group">
        <input type="text" class="form-control" placeholder="Name des Karteninhabers" v-model="tokenData.name" required>
      </div>
      <div class="form-group">
        Hinweis: Ihre Karte erfordert möglicherweise eine zusätzliche Überprüfung durch Ihre Bank.
      </div>
      <div class="form-group text-left"><button type="submit" class="btn btn-primary pr-3 pl-3">Speichern</button></div>
    </form>
  </div>
</template>


<style scoped>

</style>