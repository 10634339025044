<template>
  <div id="rx-info-bar" v-if="message && user.uuid" :class=" backgroundClass + ' d-flex align-items-center justify-content-center'" style="display: block;">
    <a href="" class="text-white d-flex align-items-center" id="rx-info-bar-message" v-on:click="(e)=>infoBarAction(e)">
      <i class="ri-information-fill"></i>
      <span class="ml-1"> {{ message }}</span>
    </a>
    <complete-profile v-on:close="closeWizard" :closed="!openWizard" :registration-infos="registrationInfos"></complete-profile>
  </div>
</template>

<script>
import {customerService} from "@/helpers/fakebackend/customer.service";
import CompleteProfile from "@/views/pages/dashboard/complete-profile";

export default {
  name: "info-bar",
  components:{
    CompleteProfile,
  },
  data(){
    return {
      message: "",
      backgroundClass: "bg-warning",
      openWizard: false,
      clickData: {},
      customer: {
        status: 1,
      },
      user: {
        status: 0,
      },
      registrationInfos: {},
    }
  },
  async mounted() {
    this.$on("update", this.updateAction);
    this.$on("click", this.clickAction);
    this.init();
  },
  created() {
    this.$root.$emit("infobar_created", true);
  },
  watch:{
    async $route (){
      await this.initUser();
      if (this.user.uuid){
        await this.init();
        if (this.message) {
          this.addContainerOffset();
        }else
          this.resetContainerOffset();
      }
    },
    async openWizard (val){
      if (!val){
        await this.init();
        if (this.message) {
          this.addContainerOffset();
        }else
          this.resetContainerOffset();
      }
    }
  },
  methods: {
    async init(){
      await this.initUser();
      if (this.user.uuid) {
        await this.initCustomer();
        await this.initRegistrationObject();
        this.showInfoBar();
      }
      if (this.$route.name === "home" && this.customer.status === 3){
        this.openWizard = true;
      }
    },
    closeWizard(){
      this.openWizard = false;
    },
    infoBarAction(e){
     e.preventDefault();
      if (this.clickData.type === "route")
        this.$router.push({name: this.clickData.name})
      else
        this.openWizard = true
    },
    resetContainerOffset(){
      const verticalMenu = document.querySelector(".vertical-menu");
      const pageContent = document.querySelector(".page-content");
      const pageTopBar = document.querySelector("#page-topbar");
      verticalMenu.style.top = "70px";
      pageTopBar.style.top = "0px";
      pageContent.style.padding = "calc(70px + 24px) calc(24px / 2) 60px calc(24px / 2)";
    },
    addContainerOffset(){
      const verticalMenu = document.querySelector(".vertical-menu");
      const pageContent = document.querySelector(".page-content");
      const pageTopBar = document.querySelector("#page-topbar");
      verticalMenu.style.top = "90px";
      pageTopBar.style.top = "20px";
      pageContent.style.padding = "calc(70px + 24px + 20px) calc(24px / 2) 60px calc(24px / 2)";
    },
    updateAction(data){
      this.message = data.message;
      this.backgroundClass = data.backgroundClass;
      if (this.message)
        this.addContainerOffset();
      else
        this.resetContainerOffset();
    },
    clickAction(data){
      this.clickData = data;
    },
    initCustomer(){
      const user = JSON.parse(localStorage.getItem("user_infos"));
      const _customer = JSON.parse(localStorage.getItem("customer_infos"));
      if (_customer) {
        this.customer = _customer;
        return;
      }
      return  customerService.getCustomer(user.customerUuid).then((data)=>{
        this.customer = data.content;
        localStorage.setItem("customer_infos", JSON.stringify(data.content));
      });
    },
    initUser(){
      const _user = JSON.parse(localStorage.getItem("user_infos"));
      if (_user) {
        this.user = _user;
      }
    },
    initRegistrationObject(){
      const _registrationInfos = JSON.parse(localStorage.getItem("registration_infos"));
      if (_registrationInfos) {
        this.registrationInfos = _registrationInfos;
        return;
      }
      return  customerService.getRegistrationInfos().then((data)=>{
        this.registrationInfos = data.content;
        localStorage.setItem("registration_infos", JSON.stringify(data.content));
      });
    },
    showInfoBar(){
      if (this.user.status === 3){
        this.message = "Sie müssen noch Ihre E-Mail-Adresse bestätigen";
        this.backgroundClass = "bg-warning";
        this.clickData = {type: "route", name: "email-verify"};
        return;
      }
      if (this.registrationInfos.hasAddress === 0){
        this.message = "Sie müssen noch Ihre Adresse hinzufügen";
        this.backgroundClass = "bg-warning";
        this.clickData = {type: "wizzard", name: "wizzard"};
        return;
      }
      if (this.registrationInfos.hasData === 0){
        this.message = "Sie müssen noch Ihre Stammdaten hinzufügen";
        this.backgroundClass = "bg-warning";
        this.clickData = {type: "wizzard", name: "wizzard"};
        return;
      }
      if (this.registrationInfos.hasPayment === 0){
        this.message = "Sie müssen noch eine Zahlungsmethode hinzufügen";
        this.backgroundClass = "bg-warning";
        this.clickData = {type: "wizzard", name: "wizzard"};
        return;
      }
      this.message = "";
    },
  }
}
</script>

<style scoped>
#rx-info-bar{
  position: fixed;
  z-index: 1003;
  width: 100%;
  text-align: left;
}
</style>