import {authJsonHeader} from "@/helpers/fakebackend/auth-header";
import {globalVariable} from "@/helpers/global-variables";
import {userService} from "@/helpers/fakebackend/user.service";

export const systemService = {
    getCompanyCountries,
    getPrivateCountries,
};

function getCompanyCountries(){
    const requestOptions = {
        method: 'GET',
        headers: authJsonHeader(),
    };
    return fetch(process.env.VUE_APP_KYB_API_URL +`info/country/company`, requestOptions).then(handleResponse);
}

function getPrivateCountries(){
    const requestOptions = {
        method: 'GET',
        headers: authJsonHeader(),
    };
    return fetch(process.env.VUE_APP_KYB_API_URL +`info/country/private`, requestOptions).then(handleResponse);
}

async function handleResponse(response) {
    if (response.ok) {
        return response.json();
    }else{
        if (response.status === 401) {
            userService.logout();
        }
        if (response.status >= 500) {
            throw {code: globalVariable.api.ERROR_500};
        }
        throw await response.json();
    }
}
